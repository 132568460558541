import React, { Component } from "react";
import "./App.css";

class App extends Component {
  componentDidMount = () => {
    document.title = "EKA.NET - Koło Naukowe Politechniki Wrocławskiej";
  };
  render() {
    return (
      <div className="App">
        <header className="header">
          <img className="logo" src={require("./logo.jpg")} alt="EKA.NET" />
          <div class="header-right">
            <a href="#us">Kim jesteśmy</a>
            <a href="#activities">Czym się zajmujemy</a>
            <a href="#community">Członkowie</a>
            <a href="#contact">Kontakt</a>
          </div>
        </header>
        <body>
          <div class="container-fluid">
            <div class="row" style={{ padding: "10px 0 0 0" }}>
              <div
                className="col-md-8"
                style={{ display: "flex", padding: "0" }}
              >
                <div className="col-md-2" style={{ padding: "0" }}>
                  <img
                    className="logo"
                    src={require("./ph1.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph2.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph3.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph4.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph5.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph6.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                </div>
                <div className="col-md-2" style={{ padding: "0" }}>
                  <img
                    className="logo"
                    src={require("./ph2.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph3.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph4.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph5.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph6.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph1.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                </div>
                <div className="col-md-2" style={{ padding: "0" }}>
                  <img
                    className="logo"
                    src={require("./ph3.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph4.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph5.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph6.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph1.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph2.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                </div>
                <div className="col-md-2" style={{ padding: "0" }}>
                  <img
                    className="logo"
                    src={require("./ph4.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph5.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph6.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph1.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph2.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph3.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                </div>
                <div className="col-md-2" style={{ padding: "0" }}>
                  <img
                    className="logo"
                    src={require("./ph5.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph6.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph1.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph2.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph3.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph4.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                </div>
                <div className="col-md-2" style={{ padding: "0" }}>
                  <img
                    className="logo"
                    src={require("./ph6.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph1.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph2.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph3.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph4.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                  <img
                    className="logo"
                    src={require("./ph5.jpg")}
                    style={{ width: "100%", padding: "2px" }}
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div
                id="us"
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "20px 0 0 0",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "large"
                }}
              >
                Kim jesteśmy?
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "20px 0",
                  fontStyle: "italic"
                }}
              >
                EKA.NET to Koło Naukowe Wydziału Elektroniki Politechniki
                Wrocławskiej zrzeszające pasjonatów i miłośników technologii
                Microsoft'owych. Nasze działania polegają na popularyzacji
                wiedzy i umiejętności dotyczących platformy .NET, chmury Azure
                oraz sztucznej inteligencji. Wspieramy osoby rozpoczynające
                swoją przygodę (np. poprzez Akademię C#), jak i bardziej
                doświadczonych kolegów (udział w licznych warsztatach,
                projektach, czy konferencjach).
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "0",
                  fontStyle: "italic"
                }}
              >
                <img
                  src={require("./ph1.jpg")}
                  style={{ height: "auto", width: "100%" }}
                />
              </div>
            </div>
            <div id="activities" class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "30px 0 0 0",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "large"
                }}
              >
                Czym się zajmujemy?
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "20px 0",
                  fontStyle: "italic"
                }}
              >
                Nasza społeczność zajmuje się organizacją różnego typu event'ów
                związanych z IT. Jedną z naszych kluczowych inicjatyw jest
                coroczna konferencja studencka IT Academic Day, czyli całodniowy
                blok prelekcji technicznych i rozwojowych, firmy partnerskie,
                zaproszeni goście specjalni (np. przedstawiciele Microsoft),
                ponad 200 uczestników, świetna zabawa, wymiana doświadczeń i
                nawiązywanie nowych kontaktów.
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "10px 0",
                  fontStyle: "italic"
                }}
              >
                Ponadto orgaznizujemy akademie programowania dla studentów,
                maratony programowania, spotkania z doświadczonymi specjalistami
                z branży. W wolnych chwilach zdarza nam się również uczestniczyć
                w konferencjach programistycznych (jesteśmy partnerami m. in.
                WROC# oraz 4Developers).
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "0",
                  fontStyle: "italic"
                }}
              >
                <img
                  src={require("./ph2.jpg")}
                  style={{ height: "auto", width: "100%" }}
                />
              </div>
            </div>
            <div id="community" class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "30px 0 0 0",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "large"
                }}
              >
                Członkowie Koła Naukowego
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "10px 0"
                }}
              >
                <ul>
                  <li>
                    Bartosz Pawelec - <i>Prezes</i>
                  </li>
                  <li>
                    Jan Dziedziela - <i>Wiceprezes ds. Finansów </i>
                  </li>
                  <li>
                    Robert Krzaczyński - <i>Wiceprezes ds. Szkoleń</i>
                  </li>
                  <li>
                    Małgorzata Mierzejewska - <i>Wiceprezes ds. Mediów</i>
                  </li>
                  <li>
                    Radosław Zegadło - <i>Specjalista ds. Reklamy</i>
                  </li>
                  <li>
                    Tomasz Zawadzki - <i>Specjalista ds. Organizacji</i>
                  </li>
                  <li>
                    Jakub Woszczyna - <i>Członek</i>
                  </li>
                  <li>
                    Mateusz Przeliorz - <i>Członek</i>
                  </li>
                  <li>
                    Michał Kałuża - <i>Członek</i>
                  </li>
                  <li>
                    Romeo Rego - <i>Członek</i>
                  </li>
                  <li>
                    Jan Kliszcz - <i>Członek</i>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "30px 0 0 0",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "large"
                }}
              >
                Opiekunowie Koła Naukowego
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "10px 0"
                }}
              >
                <ul>
                  <li>Dr inż. Jerzy Kotowski</li>
                  <li>Dr inż. Bartosz Jabłoński</li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div
                id="contact"
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "30px 0 0 0",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "large"
                }}
              >
                Kontakt
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "20px 0",
                  fontStyle: "italic"
                }}
              >
                Jeśli masz ochotę zostać członkiem naszego Koła lub masz
                interesującą ofertę współpracy to zapraszamy do kontaktu
                mailowego, bądź przez nasze kanały social media!
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{ display: "flex", padding: "0" }}
              >
                <div
                  className="col-md-3"
                  style={{
                    width: "inherit",
                    padding: "20px 0",
                    textAlign: "center",
                    fontSize: "larger"
                  }}
                >
                  <a
                    href="mailto:ekadotnet@outlook.com"
                    target="top"
                    style={{ color: "black" }}
                  >
                    <i
                      data-toggle="tooltip"
                      title="ekadotnet@outlook.com"
                      className="far fa-envelope"
                      style={{
                        cursor: "pointer"
                      }}
                    />
                  </a>
                </div>
                <div
                  className="col-md-3"
                  style={{
                    width: "inherit",
                    padding: "20px 0",
                    textAlign: "center",
                    fontSize: "larger"
                  }}
                >
                  <a
                    href="https://www.facebook.com/ekadotnet/"
                    target="top"
                    style={{ color: "black" }}
                  >
                    <i
                      data-toggle="tooltip"
                      title="https://www.facebook.com/ekadotnet/"
                      className="fab fa-facebook"
                      style={{
                        cursor: "pointer"
                      }}
                    />
                  </a>
                </div>
                <div
                  className="col-md-3"
                  style={{
                    width: "inherit",
                    padding: "20px 0",
                    textAlign: "center",
                    fontSize: "larger"
                  }}
                >
                  <a
                    href="https://www.linkedin.com/company/ekadotnet"
                    target="top"
                    style={{ color: "black" }}
                  >
                    <i
                      data-toggle="tooltip"
                      title="https://www.linkedin.com/company/ekadotnet"
                      className="fab fa-linkedin"
                      style={{
                        cursor: "pointer"
                      }}
                    />
                  </a>
                </div>
                <div
                  className="col-md-3"
                  style={{
                    width: "inherit",
                    padding: "20px 0",
                    textAlign: "center",
                    fontSize: "larger",
                    cursor: "pointer"
                  }}
                >
                  <a
                    href="https://twitter.com/EkaDotNet"
                    target="top"
                    style={{ color: "black" }}
                  >
                    <i
                      data-toggle="tooltip"
                      title="https://twitter.com/EkaDotNet"
                      className="fab fa-twitter"
                      style={{
                        cursor: "pointer"
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                className="col-md-8"
                style={{
                  width: "inherit",
                  padding: "20px 0",
                  fontStyle: "italic",
                  textAlign: "center",
                  fontSize: "small"
                }}
              >
                © 2020 EKA.NET | Politechnika Wrocławska 
              </div>
            </div>
          </div>
        </body>
      </div>
    );
  }
}

export default App;
